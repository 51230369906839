import classNames from "classnames"
import React, {CSSProperties, FC, memo, useContext} from "react"
import {ModalLoaderContext} from "../../context/modalContexts"
import {ModalLoaderPortal} from "../Modal/ModalLoadPortal"
import css from "./ScholasticLoader.module.scss"

const ScholasticLoader: FC<{modalCentered?: boolean}> = memo(({modalCentered}) => {
    const modalLoader = useContext(ModalLoaderContext)
    const className = classNames(css.container, {[css.centered]: modalLoader})

    if (modalCentered && modalLoader) {
        return (
            <ModalLoaderPortal>
                <div className={className}>
                    <ScholasticLoaderIcon />
                </div>
            </ModalLoaderPortal>
        )
    }
    return (
        <div className={className}>
            <ScholasticLoaderIcon />
        </div>
    )
})

export const ScholasticLoaderIcon: React.FC<{
    style?: CSSProperties
    className?: string
}> = ({style, className}) => {
    return (
        <div className={css.iconWrapper}>
            <div className={css.rotation} />
            <svg
                style={style}
                className={className}
                viewBox="0 0 66 66"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="33" cy="33" r="25" fill="#003A58" />
                <path
                    d="M44.9195 31.2587C46.2741 32.0243 46.2741 33.9754 44.9195 34.741L27.9841 44.3132C26.6509 45.0668 25 44.1035 25 42.5721L25 23.4277C25 21.8962 26.6509 20.9329 27.9841 21.6865L44.9195 31.2587Z"
                    fill="#FEC30C"
                />
            </svg>
        </div>
    )
}

export default ScholasticLoader
