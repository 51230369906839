import React, {ReactNode} from "react"
import styles from "./Button.module.scss"
import classNames from "classnames"
import {Icon, NameIcon} from "../Icon/Icon"
import {Box} from "../Box/Box"
import CSS from "csstype"

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    remove?: boolean
    primary?: boolean
    whiteStyles?: boolean
    danger?: boolean
    confirmDanger?: boolean
    orange?: boolean
    active?: boolean
    uploading?: boolean
    disabled?: boolean
    preventClick?: boolean
    autoFocus?: boolean
    loader?: boolean
    loadingMessage?: string
    text?: boolean
    closeDropdown?: boolean
    close?: boolean
    borderBlue?: boolean
    borderRed?: boolean
    iconType?: NameIcon
    customIcon?: React.ReactNode //! dont forget to add data-fill or data-stroke to your custom icon
    monochromeIcon?: React.ReactNode
    iconColor?: string
    type?: "button" | "reset" | "submit"
    textColor?:
        | "success"
        | "warning"
        | "error"
        | "regular-red"
        | "warm-grey"
        | "orange"
        | "light-black"
        | "white"
        | "ocean-blue"
    hoverTextColor?: "orange"
    limitedWidth?: boolean
    width?: number
    buttonRef?: React.Ref<HTMLButtonElement>
    textSize?: number
    rightIcon?: boolean
    editCreative?: boolean
    buttonClassName?: string
    containerClassName?: string
    width100percent?: boolean
    noHoverEffect?: boolean
    "data-cy"?: string
    "data-test"?: string
    backlight?: "arrow-right" | "background"
    /**
     * Correct line height to be 18px/1.125rem
     */
    correctLineHeight?: boolean
    /**
     * Add tabindex
     */
    fixTabIndex?: boolean

    dropBtnStyles?: boolean
    buttonStyles?: CSS.Properties
    contentStyles?: CSS.Properties

    dropdown?: boolean
    blueDropdown?: boolean
    redDropdown?: boolean
    dropdownTopPos?: boolean
    children?: ReactNode
    ignoreDisable?: boolean
    cloud?: "light-blue"
}

export const Button: React.FC<ButtonProps> = ({
    remove,
    primary,
    whiteStyles,
    danger,
    confirmDanger,
    orange,
    children,
    active,
    uploading,
    disabled,
    preventClick,
    loader,
    loadingMessage,
    text,
    dropdown,
    iconType,
    iconColor,
    type = "button",
    buttonRef,
    className,
    borderBlue,
    borderRed,
    textColor,
    hoverTextColor,
    limitedWidth,
    textSize,
    rightIcon,
    editCreative,
    closeDropdown,
    close,
    buttonClassName,
    containerClassName,
    width100percent,
    noHoverEffect,
    backlight,
    blueDropdown,
    redDropdown,
    "data-cy": dataCy,
    "data-test": dataTest,
    buttonStyles,
    style,
    dropBtnStyles,
    customIcon,
    dropdownTopPos,
    contentStyles,
    ignoreDisable,
    correctLineHeight,
    fixTabIndex,
    monochromeIcon,
    cloud,
    ...props
}) => {
    const textButton = text || (iconType && !dropdown)
    return (
        <div
            style={style}
            className={
                !dropBtnStyles
                    ? classNames(styles["focus-manager"], className, containerClassName, {
                          [styles.limitedWidth]: limitedWidth,
                          [styles.width100percent]: width100percent,
                          [styles.focus__disableEvents]: loader || disabled,
                      })
                    : undefined
            }
            tabIndex={fixTabIndex ? undefined : 0}
        >
            <button
                tabIndex={fixTabIndex ? undefined : -1}
                className={
                    dropBtnStyles
                        ? classNames(styles.dropBtnStyles, buttonClassName, className)
                        : classNames(styles["button-component"], className, buttonClassName, {
                              [styles.closeDropdown]: closeDropdown,
                              [styles.close]: close,
                              [styles["-remove"]]: remove,
                              [styles["-primary"]]: primary,
                              [styles["-whiteStyles"]]: whiteStyles,
                              [styles["-danger"]]: danger,
                              [styles["-confirmDanger"]]: confirmDanger,
                              [styles["-orange"]]: orange,
                              [styles["-active"]]: active,
                              [styles["-uploading"]]: uploading,
                              [styles["-loader"]]: loader,
                              [styles["-text"]]: textButton,

                              [styles["-dropdown"]]: dropdown,
                              [styles["-blue-dropdown"]]: blueDropdown,
                              [styles["-red-dropdown"]]: redDropdown,
                              [styles["-dropdown-top-pos"]]: dropdownTopPos,

                              [styles["-right-icon"]]: rightIcon,
                              [styles["-border-blue"]]: borderBlue,
                              [styles["-border-red"]]: borderRed,
                              [styles[`-icon-${iconColor}`]]: iconColor,
                              [styles[`-color-${textColor}`]]: textColor,
                              [styles.preventClick]: preventClick,
                              [styles[iconType || ""]]: iconType,
                              [styles["-editCreative"]]: editCreative,
                              [styles.noHoverEffect]: noHoverEffect,
                              [styles.backlight]: !!backlight,
                              [styles[backlight || ""]]: !!backlight,
                              [styles.ignoreDisable]: ignoreDisable,
                              [styles.correctLineHeight]: correctLineHeight,
                              [styles.cloud]: !!cloud,
                              [styles[cloud || ""]]: !!cloud,
                          })
                }
                type={type}
                disabled={!loader && disabled}
                ref={buttonRef}
                data-cy={dataCy}
                data-test={dataTest}
                style={{...buttonStyles, ...style}}
                {...props}
            >
                {monochromeIcon && (
                    <div
                        className={classNames(styles.monochromeIconBox, {
                            [styles["-margin"]]: children && !rightIcon,
                        })}
                    >
                        {monochromeIcon}
                    </div>
                )}
                {customIcon && (
                    <Box
                        className={classNames(styles["iconBox"], styles["icon-customIcon"], {
                            [styles["-margin"]]: children && !rightIcon,
                        })}
                    >
                        {customIcon}
                    </Box>
                )}
                {iconType && (
                    <Box
                        className={classNames(styles["iconBox"], {
                            [styles["-margin"]]: children && !rightIcon,
                        })}
                    >
                        <Icon
                            name={iconType}
                            className={classNames(styles["icon"], {
                                [styles[`icon-${iconType}`]]: iconType,
                                [styles[`-icon-${iconColor}`]]: iconColor,
                                [styles[`-disabled`]]: disabled,
                            })}
                        />
                    </Box>
                )}
                {children && (
                    <span
                        className={styles.content}
                        style={{fontSize: `${textSize}rem`, ...contentStyles}}
                    >
                        {loader && loadingMessage ? loadingMessage : children}
                    </span>
                )}
                {!textButton && loader && <span className={styles.loader} />}
            </button>
        </div>
    )
}
